import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

// Components
import ContentHeader from '../../components/ContentHeader';
import BalancingCard from '../../components/BalancingCard';
import Schedule from '../../components/Schedule';

export const query = graphql`
  query SeminarPageQuery($year__year: Int!, $identifier: String!) {
    years(year: { eq: $year__year }) {
      year
      color
    }
    seminars(
      year: { year: { eq: $year__year } }
      identifier: { eq: $identifier }
    ) {
      title
      contentHtml
      year {
        year
      }
      banner {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            height: 600
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            transformOptions: { fit: COVER }
          )
        }
      }
      dag_voorzitter {
        name
        link
        bio
        picture {
          childImageSharp {
            gatsbyImageData(
              width: 200
              height: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              transformOptions: { fit: COVER, cropFocus: ATTENTION }
            )
          }
        }
      }
      schedule {
        date
        times {
          begin
          end
        }
        items {
          item
          speaker {
            identifier
            name
            picture {
              childImageSharp {
                gatsbyImageData(
                  width: 200
                  height: 260
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  transformOptions: { fit: COVER, cropFocus: ATTENTION }
                )
              }
            }
          }
          subject
        }
        location {
          name
          address
          link
        }
      }
    }
  }
`;

type YearPageProps = {
  data: {
    seminars: any;
    years: any;
  };
};

export default class SeminarPage extends React.Component<YearPageProps> {
  render() {
    const seminar = this.props.data.seminars;
    const year = this.props.data.years;
    const banner = getImage(seminar.banner);
    const pictureDagVoorzitter = getImage(seminar.dag_voorzitter?.picture);
    return (
      <div className="flex flex-col items-center">
        <ContentHeader
          topper={`Justitia ${year.year}`}
          title={seminar.title}
          background={banner}
          color={year.color}
          backLink={`/${year.year}`}
          backLabel={`Justitia ${year.year}`}
          colored_banner={true}
        />
        <div className="flex flex-col gap-12 py-16 max-w-screen-md px-6">
          <div className="flex flex-col gap-4">
            <div dangerouslySetInnerHTML={{ __html: seminar.contentHtml }} />
          </div>
          <Schedule
            schedule={seminar.schedule}
            color={year.color}
            hasBanners={false}
            year={year.year}
          />

          {seminar.dag_voorzitter && (
            <BalancingCard>
              <a href={seminar.dag_voorzitter.link}>
                <div className="bg-primary p-6 rounded-2xl text-white flex flex-col sm:flex-row gap-7 hover:opacity-80 active:opacity-50 transition-opacity">
                  {pictureDagVoorzitter && (
                    <div className="overflow-hidden flex-none">
                      <GatsbyImage
                        image={pictureDagVoorzitter}
                        alt={seminar.dag_voorzitter.name}
                        className="w-full aspect-square rounded-xl corner-fix max-w-xs"
                        imgClassName="w-full aspect-square"
                      />
                    </div>
                  )}
                  <div className="flex flex-col gap-4 justify-center">
                    <div>
                      <h3 className="text-xl font-bold opacity-50">
                        Dagvoorzitter
                      </h3>
                      <h2 className="text-3xl font-black">
                        {seminar.dag_voorzitter.name}
                      </h2>
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: seminar.dag_voorzitter.bio,
                      }}
                    />
                  </div>
                </div>
              </a>
            </BalancingCard>
          )}
        </div>
      </div>
    );
  }
}

export function Head({ data }: { data: any }) {
  const year = data.years;
  const seminar = data.seminars;
  return (
    <title>
      {seminar.title} - Justitia {year.year}
    </title>
  );
}
